import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ApiResponse } from '../models/global-interfaces';
import { Credentials } from '@root/models/credentials.model';

export interface CreateCredentialsResponse {
  message: string;
}
@Injectable()
export class CredentialsService {
  private readonly http = inject(HttpClient);
  public apiUrl = environment.apiUrl;

  public readonly createCredentials = (
    newCredentials: Credentials,
  ): Observable<CreateCredentialsResponse> => {
    return this.http.post(`${this.apiUrl}user-credentials`, newCredentials).pipe(
      map((res: CreateCredentialsResponse) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public readonly updateCredentials = (
    updatedCredentials: Credentials,
  ): Observable<ApiResponse> => {
    return this.http
      .put(`${this.apiUrl}user-credentials/${updatedCredentials.id}`, updatedCredentials)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public readonly deleteCredentials = (credentialsId: number): Observable<ApiResponse> => {
    return this.http.delete(`${this.apiUrl}user-credentials/${credentialsId}`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };
}
